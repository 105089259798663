import * as React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Box,
  Button,
  Carousel,
  Grid,
  Heading,
  Image,
  Spinner,
  Text,
} from "grommet"
import { FormNextLink as FormNextLinkIcon } from "grommet-icons"
import ProjectCard from "../components/projectCard"
import usePhotos from "../utils/usePhotos"

const Section = styled(Box).attrs({ elevation: "medium" })`
  background: #f2ebe9;
  border-radius: 20px;
  padding: 30px;
  padding-top: 0;
  justify-content: space-between;
`

const IndexPage = ({ data }) => {
  // TODO: figure out how to use ResponsiveContext here
  const [size, setSize] = React.useState()
  const [currentRepos, setCurrentRepos] = React.useState([])
  const photos = usePhotos(10)

  React.useEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () =>
      setSize(window.innerWidth <= 768 ? "small" : "medium")

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  React.useEffect(() => {
    fetch("https://api.github.com/users/petabite/events?per_page=100")
      .then(response => response.json())
      .then(async data => {
        if (!Array.isArray(data)) return
        const seen = {}
        const events = data.filter(({ repo }) => {
          if (repo.name in seen) {
            return false
          }
          seen[repo.name] = true
          return true
        })
        const current = await Promise.all(
          events.slice(0, 3).map(({ repo }) =>
            fetch(repo.url)
              .then(response => response.json())
              .then(
                ({
                  name,
                  html_url,
                  description,
                  stargazers_count,
                  subscribers_count,
                }) => {
                  return {
                    name,
                    url: html_url,
                    description,
                    stargazerCount: stargazers_count,
                    watchers: { totalCount: subscribers_count },
                  }
                }
              )
          )
        )
        setCurrentRepos(current)
      })
  }, [])

  return (
    <Layout>
      <Seo title="home" />
      <Grid
        gap={size === "small" ? "medium" : "large"}
        margin="medium"
        rows={size === "small" ? ["auto", "auto", "auto"] : ["auto", "auto"]}
        columns={size === "small" ? ["auto", "auto", "auto"] : ["auto", "auto"]}
        areas={
          size === "small"
            ? [
                { name: "current", start: [0, 0], end: [1, 0] },
                { name: "code", start: [0, 1], end: [1, 1] },
                { name: "photo", start: [0, 2], end: [1, 2] },
              ]
            : [
                { name: "current", start: [0, 0], end: [1, 0] },
                { name: "code", start: [0, 1], end: [0, 1] },
                { name: "photo", start: [1, 1], end: [1, 1] },
              ]
        }
      >
        <Section gridArea="current">
          <Heading level="2">what i'm up to...</Heading>
          {currentRepos.length > 0 ? (
            <Grid
              gap="medium"
              columns={size === "small" ? ["auto"] : ["auto", "auto", "auto"]}
              rows="auto"
            >
              {currentRepos.map(repo => (
                <ProjectCard key={repo.name} {...repo} redirect />
              ))}
            </Grid>
          ) : (
            <Box align="center" justify="center" height="small">
              <Spinner size="medium" />
            </Box>
          )}
        </Section>
        <Section gridArea="code">
          <Heading level="2">featured project</Heading>
          <ProjectCard {...data.site.siteMetadata.featuredProject} />
          <Link to="code">
            <Button
              secondary
              margin={{ top: "medium" }}
              color="neutral"
              size="large"
              label={
                <Box direction="row" align="center" justify="center">
                  <Text>see all projects</Text>
                  <FormNextLinkIcon />
                </Box>
              }
            />
          </Link>
        </Section>
        <Section gridArea="photo">
          <Heading level="2">featured photos</Heading>
          <Box
            align="center"
            justify="center"
            pad="small"
            round="small"
            overflow="large"
            style={{ width: "100%", height: "100%" }}
          >
            <Carousel controls="arrows" play={3500} fill>
              {photos.map(({ id, src, igUrl }) => (
                <Box
                  key={id}
                  onClick={() => window.open(igUrl, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <Image fit="contain" src={src} />
                </Box>
              ))}
            </Carousel>
          </Box>
          <Link to="photo">
            <Button
              secondary
              color="neutral"
              size="large"
              margin={{ top: "small" }}
              label={
                <Box direction="row" align="center" justify="center">
                  <Text>see all photos</Text>
                  <FormNextLinkIcon />
                </Box>
              }
            />
          </Link>
        </Section>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        featuredProject {
          name
          description
          customName
          url
          homepageUrl
        }
      }
    }
  }
`

export default IndexPage
