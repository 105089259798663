import React from "react"

const usePhotos = (limit = 500) => {
  const [photos, setPhotos] = React.useState([])
  const apiUrl = `https://www.flickr.com/services/rest/?method=flickr.people.getPublicPhotos&api_key=2d2871aecfea769e3dec03dbc84a9506&user_id=139567981%40N08&extras=description%2Curl_w&per_page=${limit}&format=json&nojsoncallback=1`

  React.useEffect(
    () =>
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const photos = data.photos.photo.map(
            ({ description, id, url_w, height_w, width_w }) => ({
              id,
              igUrl: (description._content.match(
                /.*<a\s+(?:[^>]*?\s+)?href="([^"]*)"/
              ) || ["", ""])[1],
              src: url_w,
              height: height_w,
              width: width_w,
            })
          )
          setPhotos(photos)
        }),
    [apiUrl]
  )

  return photos
}

export default usePhotos
